<template>
  <div class="statuses">
    <h2 class="statuses__title">Statuses Widgets</h2>
    <div class="statuses__wrap">
      <h3 class="statuses__subtitle">CriticalWidget Default</h3>
      <CriticalWidget />
    </div>
    <div class="statuses__wrap">
      <h3 class="statuses__subtitle">CriticalWidget Props</h3>
      <ul class="statuses__list">
        <li class="statuses__item"><span>critical</span> - id критичности</li>
        <li class="statuses__item"><span>criticalList</span> - список выбора критичностей</li>
        <li class="statuses__item"><span>isDropDown</span> - режим редактирования</li>
      </ul>
    </div>
    <div class="statuses__wrap">
      <h3 class="statuses__subtitle">CriticalWidget All</h3>
      <ul class="statuses__content">
        <li><CriticalWidget :critical="1" /></li>
        <li><CriticalWidget :critical="2" /></li>
        <li><CriticalWidget :critical="3" /></li>
        <li><CriticalWidget :critical="4" /></li>
      </ul>
    </div>
    <div class="statuses__wrap">
      <h3 class="statuses__subtitle">CriticalWidget DropDown</h3>
      <CriticalWidget v-model="critical" :critical="critical.id" :isDropDown="true" :criticalList="criticalList" />
    </div>
    <div class="statuses__container">
      <h2 class="statuses__title">Status Problem ISP</h2>
      <div class="statuses__wrap">
        <h3 class="statuses__subtitle">StatusProblem Default</h3>
        <StatusProblem :status="1" />
      </div>
      <div class="statuses__wrap">
        <h3 class="statuses__subtitle">StatusProblem Props</h3>
        <ul class="statuses__list">
          <li class="statuses__item"><span>status</span> - id статуса</li>
          <li class="statuses__item"><span>statusList</span> - список выбора статусов</li>
          <li class="statuses__item"><span>isDropDown</span> - режим редактирования</li>
        </ul>
      </div>
      <div class="statuses__wrap">
        <h3 class="statuses__subtitle">StatusProblem All</h3>
        <ul class="statuses__content">
          <li><StatusProblem :status="1" /></li>
          <li><StatusProblem :status="2" /></li>
          <li><StatusProblem :status="5" /></li>
          <li><StatusProblem :status="7" /></li>
          <li><StatusProblem :status="3" /></li>
          <li><StatusProblem :status="6" /></li>
          <li><StatusProblem :status="4" /></li>
        </ul>
      </div>
      <div class="statuses__wrap">
        <h3 class="statuses__subtitle">StatusProblem DropDown</h3>
        <StatusProblem :status="status.id" v-model="status" :isDropDown="true" :statusList="statusList" />
      </div>
    </div>
  </div>
</template>

<script>
import CriticalWidget from "@/modules/UiKit/components/statuses/StatusCritical";
import StatusProblem from "@/modules/UiKit/components/statuses/StatusProblem";
export default {
  name: "ISPComponents",
  mixins: [],
  props: [],
  components: { CriticalWidget, StatusProblem },
  data() {
    return {
      criticalList: [
        {
          id: 1,
          name: 'Блокирующая'
        },
        {
          id: 2,
          name: 'Критическая'
        },
        {
          id: 3,
          name: 'Значительная'
        },
        {
          id: 4,
          name: 'Комфорт'
        },
      ],
      critical:
        {
          id: 1,
          name: 'Блокирующая'
        },
      statusList: [
        {
          id: 1,
          label: 'Создано'
        },
        {
          id: 2,
          label: 'В работе'
        },
        {
          id: 3,
          label: 'Готова'
        },
        {
          id: 4,
          label: 'Отклонена'
        },
        {
          id: 5,
          label: 'Проверка'
        },
        {
          id: 6,
          label: 'Закрыта'
        },
        {
          id: 7,
          label: 'Доработка'
        },
      ],
      status:
        {
          id: 1,
          label: 'Блокирующая'
        }
    };
  },
  mounted() {},
  methods: {},
  computed: {
    setListClass() {
      return "display: flex; align-items: center; flex-wrap: wrap; margin: 0; padding: 0; list-style: none;";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.statuses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__container {
    width: 100%;
    margin: 48px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 0 8px;
    }
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
